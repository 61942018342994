<template>
  <div class="bodys" style="padding-bottom: 20px">
    <!-- 顶部部分 -->
    <dv-border-box-11 :title="title" :titleWidth="290">
      <div class="topStyle">
        <div>&nbsp;</div>
      </div>
      <!-- 主体部分 -->
      <div class="mainStyle">
        <!-- 左边数据表格 -->
        <div>
          <dv-border-box-10>
            <div><img style="width: 100%" alt="" src="@/assets/demo1.png"></div>
          </dv-border-box-10>
          <dv-border-box-10>
            <div>项目类型分布比例</div>
            <div id="container01"></div>
          </dv-border-box-10>
        </div>
        <!-- 中间部分 -->
        <div>
          <!-- 地图 -->
          <dv-border-box-10>
            <div>五大产业分布比例</div>
            <div id="container02" style="width: 100%"></div>
          </dv-border-box-10>
          <dv-border-box-10>
            <div>项目地区分布比例</div>
            <div id="container03" style="height: 90%"></div>
          </dv-border-box-10>
        </div>
        <!-- 右边数据表格 -->
        <div>
          <dv-border-box-10>
            <div>热门标签</div>
            <div><img style="width: 100%" alt="" src="@/assets/demo2.png"></div>
          </dv-border-box-10>
          <dv-border-box-10>
            <div>项目金额区间分布</div>
            <div id="container04"></div>
          </dv-border-box-10>
        </div>
      </div>
    </dv-border-box-11>
  </div>
</template>

<script>
import * as g2plot from '@antv/g2plot';
export default {
  name: 'CMView',
  data() {
    return {
      // 页面标题
      title: ' 长三角走出去数据管理平台 ',
      // 场景
      scene: null,
      // 环境光
      ambienLight: null,
      // 加载的场景
      loaderScene: null,
      // 渲染器
      renderer: null,
      // 相机
      camera: null
    };
  },
  mounted() {
    // 数据表格加载
    setTimeout(() => {
      this.loadContainer01();
      this.loadContainer02();
      this.loadContainer03();
      this.loadContainer04();
    }, 500);
  },
  methods: {
    loadContainer01() {
      this.$nextTick(() => {
        const data = [
          { type: '融资类', value: 18 },
          { type: '并购类', value: 82 },
        ];

        const piePlot = new g2plot.Pie('container01', {
          appendPadding: 10,
          data,
          angleField: 'value',
          colorField: 'type',
          radius: 1,
          color: ['#e98714', '#9600ff'],
          innerRadius: 0.6,
          label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
              textAlign: 'center',
              fontSize: 14,
            },
          },
          statistic:{
            title:false,
            content:false
          },
          interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
        });

        piePlot.render();

      })

    },
    loadContainer02() {
      this.$nextTick(() => {
        const column = new g2plot.Column('container02', {
          data:[
            { genre: '新制造', sold: 58 },
            { genre: '新医疗', sold: 48 },
            { genre: '新一代信息技术', sold: 138 },
            { genre: '新能源新材料', sold: 62 },
            { genre: '新消费新文娱', sold: 45 },
          ],
          xField: 'genre',
          yField: 'sold',
          isGroup: true,
          columnWidthRatio: 0.3,
          // isStack: true,
          // seriesField: 'product_sub_type',
          // groupField: 'sex',
          columnStyle: {
            fill: 'l(90) 0:#15a4ff 1:#000eff',
          },
        });
        column.render();

      })

    },
    loadContainer03() {
      this.$nextTick(() => {
        const data = [
          { time: '法国', value: 600, count: 800 },
          { time: '日本', value: 820, count: 1000 },
          { time: '美国', value: 710, count: 730 },
          { time: '墨西哥', value: 860, count: 1000 },
          { time: '泰国', value: 530, count: 1020 },
          { time: '越南', value: 1000, count: 1060 },
          { time: '澳大利亚', value: 480, count: 900 },
          { time: '芬兰', value: 830, count: 850 },
          { time: '新加坡', value: 770, count: 880 },
          { time: '印度尼西亚', value: 560, count: 600 },
        ];

        const dualAxes = new g2plot.DualAxes('container03', {
          data: [data, data],
          xField: 'time',
          yField: ['value', 'count'],
          legend: {
            position: 'top-right'
          },
          meta: {
            count: {
              min: 0,
              max: 1000,
            },
          },
          geometryOptions: [
            {
              geometry: 'column',
              columnStyle: {
                fill: 'l(90) 0:#15a4ff 1:#000eff',
              },
            },
            {
              geometry: 'line',
              lineStyle: {
                color: "#3fc969",
                lineWidth: 2,
              },
              point: {
                size: 5,
              },
            },
          ],
        });

        dualAxes.render();
      })

    },
    loadContainer04() {
      this.$nextTick(() => {
        const column = new g2plot.Column('container04', {
          data:[
            { genre: '100万', sold: 622 },
            { genre: '500万', sold: 564 },
            { genre: '800万', sold: 609 },
            { genre: '1000万', sold: 802 },
            { genre: '1亿', sold: 612 },
          ],
          xField: 'genre',
          yField: 'sold',
          isGroup: true,
          columnWidthRatio: 0.4,
          // isStack: true,
          // seriesField: 'product_sub_type',
          // groupField: 'sex',
          columnStyle: {
            fill: 'l(90) 0:#15a4ff 1:#000eff',
          },
        });
        column.render();
      })

    }
  }
};
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.mainStyle {
  color: #ffffff;
  margin: 2% 5%;
  height: 85%;
  display: flex;

  > div:nth-child(1),
  div:nth-child(3) {
    width: 25%;

    > div {
      width: 100%;
      height: 50%;
      margin: 4% 0;

      > div {
        > div {
          display: flex;
          justify-content: center;
          width: 94%;
          margin: 0 3%;
        }

        > div:nth-child(1) {
          padding-top: 2%;
          font-size: 20px;
          font-family: 'FZY4JW';
          color: #35f3fd;
        }

        > div:nth-child(2) {
          height: 79%;
        }
      }
    }
  }

  > div:nth-child(2) {
    width: 50%;
    margin: 0 1%;

    > div {
      padding: 0 2%;
    }

    > div:nth-child(1) {
      margin-top: 2%;
      height: 50%;

      > div > div {
        position: absolute;
        top: 2.5%;
        height: 95% !important;
      }
    }

    > div:nth-child(2) {
      margin-top: 2%;
      height: 50%;

      > div {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.topStyle {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6% 6% 0 6%;
  font-size: 18px;

  > div:nth-child(2) {
    font-size: 32px;
    font-family: 'FZZiYHJW';
    letter-spacing: 3px;
    margin-left: 4%;
  }
}

.bodys {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-image: url('@/assets/background.png');
  background-size: 100% 100%;
}

@font-face {
  font-family: 'FZZiYHJW';
  src: url('@/assets/font/FZZiYHJW.TTF');
}

@font-face {
  font-family: 'FZY4JW';
  src: url('@/assets/font/FZY4JW.TTF');
}
</style>

<template>
  <div class="h-100">
    <div class="prev-next-btn-group h-100">
      <div @click="toPrev()" class="prev-btn ">
        <!--                <img src="@/assets/images/left.png">-->
      </div>
      <div class="h-100">
        <slot>
          <div v-if="fileName">
            <img :src="require('@/assets/images/temp/Compress_' + fileName + '.jpg')">
          </div>
        </slot>
      </div>
      <div @click="toNext()" class="next-btn">
        <!--                <img src="@/assets/images/right.png">-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrevNextButtons",
  props:{
    file:{
      type:String
    }
  },
  data: function () {
    const files = ['1-1','1-2','1-3','2-1','2-2','2-3','2-4','3-1','3-2','3-3','4-1','4-2','4-3','5-1','5-2','5-3','底图',]
    return {
      files,
      fileName: '1-1',
    }
  },
  watch:{
    file:{
      immediate: true,
      handler:function () {
        const file = this.file;
        const files = this.files
        let index = -1;
        for (const key in files) {
          if(files[key] === file) {
            index = parseInt(key)
            break;
          }
        }
        if(index === -1) {
          this.fileName = '首页'
        } else {
          this.fileName = file
        }
        this.index = index;
      },
    }
  },
  methods:{
    toPrev(){
      if(this.index > 0) {
        this.$changePage('/page/' + this.files[this.index - 1])
      } else {
        this.$changePage('#/')
      }
    },
    toNext() {
      if(this.file === '0') {
        this.$changePage('#/')
      } else if(this.index < this.files.length - 1) {
        this.$changePage('/page/' + this.files[this.index + 1])
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.prev-next-btn-group{
  position: relative;
  .prev-btn, .next-btn{
    position: absolute;
    right: 1%;
    top: 18%;
    cursor: pointer;
    padding: 20px 40px;
    width: 20%;
    height: 80%;
    z-index: 11;
    img{
      width: 70px;
    }
  }
  .prev-btn{
    left: 1%;
  }
}
</style>

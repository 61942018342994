<template>
  <div class="bodys" style="padding-bottom: 20px">
    <!-- 顶部部分 -->
    <dv-border-box-11 :title="title" :titleWidth="290" >
      <div class="topStyle">
        <div>&nbsp;</div>
      </div>
      <!-- 主体部分 -->
      <div class="mainStyle">
        <!-- 左边数据表格 -->
        <div>
          <dv-border-box-10>
            <div>“一带一路”沿线国家投资</div>
            <div id="container01"></div>
          </dv-border-box-10>
          <dv-border-box-10>
            <div>对外投资排名前十的国家</div>
            <div id="container02"></div>
          </dv-border-box-10>
          <!--                    <dv-border-box-10>-->
          <!--                        <div>投资变化趋势(按行业)</div>-->
          <!--                        <div id="container03"></div>-->
          <!--                    </dv-border-box-10>-->
        </div>
        <!-- 中间部分 -->
        <div>
          <!-- 地图 -->
          <div>
            <div id="containerMap" style="width: 100%; height: 100%"></div>
          </div>
          <!-- 三维跑车模型 -->
          <div>
            <div id="modelCar"></div>
          </div>
        </div>
        <!-- 右边数据表格 -->
        <div>
          <dv-border-box-10>
            <div>对外投资行业分布</div>
            <div id="container04"></div>
          </dv-border-box-10>
          <dv-border-box-10>
            <div>对外投资流量洲别分布</div>
            <div id="container05"></div>
          </dv-border-box-10>
        </div>
      </div>
    </dv-border-box-11>
  </div>
</template>

<script>
import * as g2plot from '@antv/g2plot';
import * as l7plot from '@antv/l7plot';
export default {
  name: 'YRD',
  data() {
    return {
      // 页面标题
      title: ' 长三角走出去综合服务平台 ',
      // 场景
      scene: null,
      // 环境光
      ambienLight: null,
      // 加载的场景
      loaderScene: null,
      // 渲染器
      renderer: null,
      // 相机
      camera: null
    };
  },
  mounted() {
    // 地图加载
    this.loadMap();
    // 数据表格加载
    setTimeout(() => {
      this.loadContainer01();
      this.loadContainer02();
      this.loadContainer04();
      this.loadContainer05();
    }, 500);
  },
  methods: {
    loadMap() {
      fetch('https://gw.alipayobjects.com/os/alisis/geo-data-v0.1.2/administrative-data/area-list.json')
        .then((response) => response.json())
        .then((list) => {
          const data = list
            .filter(({ level }) => level === 'district')
            .map((item) => Object.assign({}, item, { value: Math.random() * 5000 }));
          new l7plot.L7Plot('containerMap', {
            logo: false,
            map: {
              type: 'map',
              center: [120.19382669582967, 30.258134],
            },
            plots: [{
              type: 'choropleth',
              source: {
                data: data,
                joinBy: {
                  sourceField: 'adcode',
                  geoField: 'adcode'
                }
              },
              viewLevel: {
                level: 'province',
                adcode: '310000',
                granularity: 'district'
              },
              autoFit: true,
              color: {
                field: 'value',
                value: ['#B8E1FF', '#7DAAFF', '#3D76DD', '#0047A5', '#001D70'],
                scale: { type: 'quantize' }
              },
              style: {
                opacity: 1,
                stroke: '#ccc',
                lineWidth: 0.6,
                lineOpacity: 1
              },
              label: {
                visible: true,
                field: 'name',
                style: {
                  fill: '#fff',
                  opacity: 0.8,
                  fontSize: 10,
                  stroke: '#000',
                  strokeWidth: 1.5,
                  textAllowOverlap: false,
                  padding: [5, 5]
                }
              },
            },
              {
                type: 'dot',
                zIndex: 2,
                source: {
                  data: [{
                    name:'上市公司数量',
                    lng: 121.69382669582967,
                    lat: 31.158134,
                    value: 7000
                  },{
                    name:'投资机构数量',
                    lng: 121.29382669582967,
                    lat: 31.158134,
                    value: 9000
                  },{
                    name:'专业机构服务数量',
                    lng: 121.29382669582967,
                    lat: 30.858134,
                    value: 3000
                  }],
                  parser: { type: 'json', x: 'lng', y: 'lat' },
                },
                color: '#66ff1a',
                size: {
                  field: 'value',
                  value: ({ value }) => value / 200,
                },
                style: {
                  opacity: 1,
                  stroke: '#fff',
                  strokeWidth: 1,
                },
                tooltip: {
                  items: ['name', 'value'],
                },
              },
            ],
            state: {
              active: { stroke: 'black', lineWidth: 1 }
            },
            tooltip: {
              items: ['name', 'adcode', 'value']
            }
          });
        });
    },
    loadContainer05() {

      this.$nextTick(() => {
        const data = [
          { type: '北美洲', value: 3.4 },
          { type: '大洋洲', value: 1.3 },
          { type: '非洲', value: 2.2 },
          { type: '欧洲', value: 6.2 },
          { type: '拉丁美洲', value: 34 },
          { type: '亚洲', value: 52.9 },
        ];

        const piePlot = new g2plot.Pie('container05', {
          legend: true,
          appendPadding: 10,
          data,
          angleField: 'value',
          colorField: 'type',
          radius: 1,
          // color: ['#e98714', '#9600ff'],
          label: {
            type: 'inner',
            offset: '30%',
            content: '{name}\n{percentage}',
            style: {
              textAlign: 'center',
            },
          },
          statistic:{
            title:false,
            content:false
          },
          interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
        });

        piePlot.render();

      })
    },
    loadContainer04() {
      this.$nextTick(() => {
        const column = new g2plot.Column('container04', {
          data:[
            { genre: '租赁和商务服务业', sold: 28.3 },
            { genre: '信息传输、软件和信息技术服务业', sold: 18.9 },
            { genre: '采矿业', sold: 5.3 },
            { genre: '批发和零售业', sold: 13.6 },
            { genre: '电力、热力、燃气及水的生产和供应业', sold: 2.7 },
            { genre: '交通运输、仓储和邮政业', sold: 16.5 },
            { genre: '住宿和餐饮业', sold: 1.8 },
            { genre: '科学研究和技术服务业', sold: 21.1 },
            { genre: '农、林、牧、渔业', sold: 1.4 },
            { genre: '制造业', sold: 23.3 },
          ],
          xField: 'genre',
          yField: 'sold',
          isGroup: true,
          columnWidthRatio: 0.8,
          // isStack: true,
          // seriesField: 'product_sub_type',
          // groupField: 'sex',
          columnStyle: {
            fill: 'l(90) 0:#15a4ff 1:#000eff',
          },
          meta: {
            sold: {
              alias: '金额',
            },
          },
        });
        column.render();

      })
    },
    loadContainer02() {
      this.$nextTick(() => {
        const data = [
          { time: '中国香港', value: 45.6, count: 34.5 },
          { time: '开曼群岛', value: 24.0, count: 18.1 },
          { time: '英属维尔京群岛', value: 20.9, count: 15.8 },
          { time: '新加坡', value: 15.9, count: 12.1 },
          { time: '印度尼西亚', value: 5.7, count: 4.3 },
          { time: '英国', value: 5.3, count: 4.0 },
          { time: '美国', value: 3.4, count: 2.6 },
          { time: '德国', value: 1.8, count: 1.4 },
          { time: '马来西亚', value: 1.7, count: 1.3 },
          { time: '澳大利亚', value: 1.1, count: 0.8 },
        ];

        const dualAxes = new g2plot.DualAxes('container02', {
          data: [data, data],
          xField: 'time',
          yField: ['value', 'count'],
          legend: {
            position: 'top-right'
          },
          meta: {
            count: {
              alias: '占比',
              min: 0,
              max: 50,
            },
            value: {
              alias: '流量',
            },
          },
          geometryOptions: [
            {
              geometry: 'column',
              columnStyle: {
                fill: 'l(90) 0:#15a4ff 1:#000eff',
              },
            },
            {
              geometry: 'line',
              lineStyle: {
                color: "#3fc969",
                lineWidth: 2,
              },
              point: {
                size: 5,
              },
            },
          ],
        });

        dualAxes.render();
      })
    },
    loadContainer01() {
      this.$nextTick(() => {
        const data = [
          { time: '2017', value: 19.9, count: 127.1 },
          { time: '2018', value: 8.3, count: 140.8 },
          { time: '2019', value: 6.5, count: 156.2 },
          { time: '2020', value: 12.8, count: 162.5 },
          { time: '2021', value: 23.7, count: 207.7 },
        ];

        const dualAxes = new g2plot.DualAxes('container01', {
          data: [data, data],
          xField: 'time',
          yField: ['value', 'count'],
          legend: {
            position: 'top-right'
          },
          meta: {
            count: {
              alias: '存量',
              min: 0,
              max: 220,
            },
            value: {
              alias: '流量',
            },
          },
          geometryOptions: [
            {
              geometry: 'column',
              columnStyle: {
                fill: 'l(90) 0:#15a4ff 1:#000eff',
              },
            },
            {
              geometry: 'line',
              lineStyle: {
                color: "#3fc969",
                lineWidth: 2,
              },
              point: {
                size: 5,
              },
            },
          ],
        });

        dualAxes.render();
      })
    }
  }
};
</script>

<style lang="less" scoped>
.mainStyle {
  color: #ffffff;
  margin: 2% 5%;
  height: 85%;
  display: flex;
  > div:nth-child(1),
  div:nth-child(3) {
    width: 25%;
    > div {
      width: 100%;
      height: 50%;
      margin: 4% 0;
      > div {
        > div {
          display: flex;
          justify-content: center;
          width: 94%;
          margin: 0 3%;
        }
        > div:nth-child(1) {
          padding-top: 2%;
          font-size: 20px;
          font-family: 'FZY4JW';
          color: #35f3fd;
        }
        > div:nth-child(2) {
          height: 79%;
        }
      }
    }
  }
  > div:nth-child(2) {
    width: 50%;
    > div {
      padding: 0 2%;
    }
    > div:nth-child(1) {
      margin-top: 1%;
      height: 100%;
      > div > div {
        position: absolute;
        top: 2.5%;
        height: 95% !important;
      }
    }
    > div:nth-child(2) {
      margin-top: 1%;
      height: 35%;
      > div {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.topStyle {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.6% 6% 0 6%;
  font-size: 18px;
  > div:nth-child(2) {
    font-size: 32px;
    font-family: 'FZZiYHJW';
    letter-spacing: 3px;
    margin-left: 4%;
  }
}

.bodys {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/background.png');
  background-size: 100% 100%;
}

@font-face {
  font-family: 'FZZiYHJW';
  src: url('@/assets/font/FZZiYHJW.TTF');
}

@font-face {
  font-family: 'FZY4JW';
  src: url('@/assets/font/FZY4JW.TTF');
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    piwikUrl: '',
  },
  getters: {
    piwikUrl: state => state.piwikUrl,
  },
  mutations: {
    SET_PIWIK_URL: (state, piwikUrl) => {
      state.piwikUrl = piwikUrl
    },
  },
  actions: {
  },
  modules: {
  }
})

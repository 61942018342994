<template>
  <div class="mw-hide" >
    <img :src="piwikUrl" style="border:0" alt=""/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props:{
    title:{
      type:String
    },
    subTitle:{
      type:String
    }
  },
  name: "PiwikComponent",
  computed: {
    ...mapGetters(['piwikUrl']),
  },
  watch:{
    title:{
      immediate: true,
      handler:function () {
        this.$store.commit("SET_PIWIK_URL", this.$createPiwikUrl(this.title, this.subTitle))
      }
    },
    subTitle:{
      immediate: true,
      handler:function () {
        this.$store.commit("SET_PIWIK_URL", this.$createPiwikUrl(this.title, this.subTitle))
      }
    },
  },
  data:()=>({
  }),
}
</script>

<style scoped>
.mw-hide{
    width: 0;
    height: 0;
    opacity: 0;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// DataV依赖
import dataV from '@jiaminghi/data-view';
import { changeDefaultConfig } from '@jiaminghi/charts'
import '@/assets/base.scss'
import {changePage, createPiwikUrl} from "@/utils";
// 全局使用DataV
Vue.use(dataV);
Vue.config.productionTip = false
const config = {
  title: {
    style:{fill:'#fff',},
  },
  xAxis: {
    nameTextStyle:{fill:'#fff',},
    axisLabel:{style:{fill:'#fff'},},
  },
  yAxis: {
    axisLabel:{style:{fill:'#fff'},},
    nameTextStyle:{fill:'#fff',},
  },
}
changeDefaultConfig("title",config.title)
changeDefaultConfig("xAxis",config.xAxis)
changeDefaultConfig("yAxis",config.yAxis)


Vue.prototype.$changePage = (url, noHistory, backUrl) => {
  return changePage(router, url, noHistory, backUrl)
}
Vue.prototype.$createPiwikUrl = (title, subTitle, notDoCreate) => {
  return createPiwikUrl(title, subTitle, store, notDoCreate)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div>
    <prev-next-buttons file="首页" >
      <div >
        <img src="@/assets/images/temp/Compress_首页.jpg">
      </div>
    </prev-next-buttons>
    <piwik title="图片首页" />
  </div>
</template>

<script>

import PrevNextButtons from '@/components/PrevNextButtons.vue'
import Piwik from "@/components/PiwikComponent.vue";
export default {
  name: "Page0View",
  components: {Piwik, PrevNextButtons},
  data:function () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

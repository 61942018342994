import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import WorldView from "@/views/WorldView.vue";
import ChinaMergerView from "@/views/ChinaMergerView.vue";
import IndexView from "@/views/IndexView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/world',
    name: 'world',
    component: WorldView
  },
  {
    path: '/china_merger',
    name: 'china_merger',
    component: ChinaMergerView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
  },
  {
    path: '/aboutTest',
    name: 'aboutTest',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutTestView.vue')
  },
  {
    path: '/chinaMergerImage',
    name: 'chinaMergerImage',
    component: () => import('@/views/ChinaMergerImageView.vue')
  },
  {
    path: '/chinaMergerSaleImageView',
    name: 'chinaMergerSaleImageView',
    component: () => import('@/views/ChinaMergerSaleImageView.vue')
  },
  {
    path: '/page/1-1',
    name: 'Page11',
    component: () => import('@/views/demo/Page11.vue')
  },
  {
    path: '/page/1-2',
    name: 'Page12',
    component: () => import('@/views/demo/Page12.vue')
  },
  // {
  //   path: '/page/1-3',
  //   name: 'Page13',
  //   component: () => import('@/views/demo/Page13.vue')
  // },
  {
    path: '/page/3-3',
    name: 'Page',
    component: () => import('@/views/demo/Page35.vue')
  },
  {
    path: '/page/:file',
    name: 'Page',
    component: () => import('@/views/demo/Page.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
